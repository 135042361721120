import React, { useMemo, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './UserPanel.scss';


export default function UserPanel({ menuMode }) {
    const { user, signOut } = useAuth();
    const navigate = useNavigate();
    
    const navigateToChangePassword = useCallback(() => {
        navigate("/changepassword");
    }, [navigate]);

    const menuItems = useMemo(() => ([
    {
        text: 'Change Password',
        icon: 'user',
        onClick: navigateToChangePassword
    },
    {
        text: 'Logout',
        icon: 'runner',
        onClick: signOut
    }
    ]), [navigateToChangePassword, signOut]);

    // console.log(user);
    
    return (
        <div className={'user-panel'}>
            <div className={'user-info'}>
                {/* <div className={'image-container'}>
                <div
                    style={{
                    background: `url(${user.avatarUrl}) no-repeat #fff`,
                    backgroundSize: 'cover'
                    }}
                    className={'user-image'} />
                </div> */}
                <div className={'user-name'}>{user.username}</div>
            </div>

            {menuMode === 'context' && (
                <ContextMenu
                items={menuItems}
                target={'.user-button'}
                showEvent={'dxclick'}
                width={210}
                cssClass={'user-menu'}
                >
                <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
                </ContextMenu>
            )}
            {menuMode === 'list' && (
                <List className={'dx-toolbar-menu-action'} items={menuItems} />
            )}
        </div>
    );
}
