// export const navigation = [
//     {
//         text: 'Home',
//         path: '/home',
//         icon: 'home'
//     },
//     {
//         text: 'Examples',
//         icon: 'folder',
//         items: [
//             {
//                 text: 'Profile',
//                 path: '/profile'
//             },
//             {
//                 text: 'Tasks',
//                 path: '/tasks'
//             }
//         ]
//     }
// ];

import PersonAddIcon from "./svg/Property 1=person_add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
import RequestQuoteIcon from "./svg/Property 1=request_quote_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
import PatientListIcon from "./svg/Property 1=patient_list_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"

export const navigationPartner = [
    // {
    //     text: 'Home',
    //     path: '/home',
    //     icon: 'home'
    // },
    {
        text: 'User List',
        path: '/partner-customer',
        icon: PatientListIcon
    },
    {
        text: 'Billing',
        path: '/partner-billing',
        icon: RequestQuoteIcon
    },
    {
        text: 'Add New User',
        path: '/partner-adduser',
        icon: PersonAddIcon
    },
];

export const navigationCustomer = [
    // {
    //     text: 'Home',
    //     path: '/home',
    //     icon: 'home'
    // },
    {
        text: 'User List',
        path: '/customer-doctor',
        icon: PatientListIcon
    },
    {
        text: 'Add New User',
        path: '/customer-addusers',
        icon: PersonAddIcon
    },
];

export const navigationDoctor = [
    // {
    //     text: 'Home',
    //     path: '/home',
    //     icon: 'home'
    // },
    {
        text: 'Patient List',
        path: '/doctor-patient',
        icon: PatientListIcon
    },
    {
        text: 'Add New User',
        path: '/doctor-addusers',
        icon: PersonAddIcon
    },
];
