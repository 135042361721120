import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { PartnerCustomerListDatagrid, PartnerDoctorChangepasswordForm, PartnerPatientListDatagrid } from '../../components';
  import HomeHealthIcon from "../../svg/Property 1=home_health_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import StethoscopeIcon from "../../svg/Property 1=stethoscope_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";

  export default function PartnerDoctorChangepasswordPage() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [tabSelectedIndex, setTabSelectedIndex] = useState(1)

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;
        var isReselected = false;

        if (itemIndex == tabSelectedIndex) {
            isReselected = true
        }

        setTabSelectedIndex(itemIndex)

        if (itemIndex == 1 && isReselected == true) {
            navigate('/partner-customer?tabSelectedIndex=1');
        }
    }

    useEffect(() => {
        if (user.usertype == "CP" || user.usertype == "DIST") {

        } else {
            navigate('/');
        }
    }, []);

    return (
        <React.Fragment>
            <TabPanel className={'content-block dx-card responsive-paddings'} defaultSelectedIndex={1} swipeEnabled={false} scrollByContent={true} showNavButtons={true} onTitleClick={onTitleClick}>
                <Item title="Organization" icon={HomeHealthIcon}> 
                    <PartnerCustomerListDatagrid />
                </Item>

                <Item title="Doctor" icon={StethoscopeIcon}> 
                    <PartnerDoctorChangepasswordForm />
                </Item>
    
                <Item title="Patient" icon={PersonIcon}> 
                    {<PartnerPatientListDatagrid />}
                </Item>
            </TabPanel>
        </React.Fragment>
      );
  }