import React from 'react';
import { ChangePasswordForm } from '../../components';

export default function ChangePasswordPage() {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Change Password</h2>
            <div className={'content-block'}>
                <ChangePasswordForm/>
            </div>
        </React.Fragment>
    )
}