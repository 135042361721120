// import React, { useState, useRef, useCallback } from 'react';
// import { Link, useNavigate } from "react-router-dom";
// import Form, {
//   Item,
//   Label,
//   ButtonItem,
//   ButtonOptions,
//   RequiredRule,
//   EmailRule
// } from 'devextreme-react/form';
// import LoadIndicator from 'devextreme-react/load-indicator';
// import notify from 'devextreme/ui/notify';
// import { resetPassword } from '../../api/auth';
// import './ResetPasswordForm.scss';

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
	Item,
	Label,
	ButtonItem,
	ButtonOptions,
	RequiredRule,
	EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';

// const notificationText = 'We\'ve sent a link to reset your password. Check your inbox.';

// export default function ResetPasswordForm() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const formData = useRef({ email: '', password: '' });

//   const onSubmit = useCallback(async (e) => {
//     e.preventDefault();
//     const { email } = formData.current;
//     setLoading(true);

//     const result = await resetPassword(email);
//     setLoading(false);

//     if (result.isOk) {
//       navigate('/login');
//       notify(notificationText, 'success', 2500);
//     } else {
//       notify(result.message, 'error', 2000);
//     }
//   }, [navigate]);

//   return (
//     <form className={'reset-password-form'} onSubmit={onSubmit}>
//       <Form formData={formData.current} disabled={loading}>
//         <Item
//           dataField={'email'}
//           editorType={'dxTextBox'}
//           editorOptions={emailEditorOptions}
//         >
//           <RequiredRule message="Email is required" />
//           <EmailRule message="Email is invalid" />
//           <Label visible={false} />
//         </Item>
//         <ButtonItem>
//           <ButtonOptions
//             elementAttr={submitButtonAttributes}
//             width={'100%'}
//             type={'default'}
//             useSubmitBehavior={true}
//           >
//             <span className="dx-button-text">
//               {
//                 loading
//                   ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
//                   : 'Reset my password'
//               }
//             </span>
//           </ButtonOptions>
//         </ButtonItem>
//         <Item>
//           <div className={'login-link'}>
//             Return to <Link to={'/login'}>Sign In</Link>
//           </div>
//         </Item>
//       </Form>
//     </form>
//   );
// }

// const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
// const submitButtonAttributes = { class: 'submit-button' };

export default function ResetPasswordForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [formData, setformData] = useState({ password: '', confirmPassword: '' });
    const formDataRef = useRef(null)

    const location = useLocation();

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

	const onSubmit = useCallback(async (e) => {
		e.preventDefault();

		setLoading(true);

        // console.log(location.state?.username)
        // console.log(location.state?.userToken)
        // console.log(location.state?.otpToken)
        // console.log(formData.password)
        // console.log(formData.confirmPassword)

        try {
            const response = await axios.post(backendlink + 'user/changepasswordbyotp',
            {
                username: location.state?.username,
                userToken: location.state?.userToken,
                otpToken: location.state?.otpToken,
                password: formData.password,
                confirmPassword: formData.confirmPassword,
            }, 
            {
                headers: {
                    
                }        
            }).then((res)=>{
                setLoading(false);

                if (res.data.errorno == 0){
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'success',
                        message: 'Password changed',
                    });    

                    setTimeout(function() {
                        navigate('/');
                    }, 1000);                    
                } else{
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'error',
                        message: res.data.errmessage,
                    });    
                }
            });
        } catch (error) {
            setLoading(false);

            // console.log(error);
            alert(error);
        }
	}, []);

    useEffect(() => {
        // console.log(location.state)
        
        if (location.state?.username == null || location.state?.userToken == null || location.state?.otpToken == null) {
            navigate('/');
        } else {

        }
    }, []);

    const passwordEditorOptions = { stylingMode: 'filled',  placeholder: '', mode: 'password' };

    return (
		<form className={'login-form'} onSubmit={onSubmit}>
			<Form formData={formData} ref={formDataRef} disabled={loading}>
				<Item
				    dataField={'password'}
				    editorType={'dxTextBox'}
                    editorOptions={passwordEditorOptions}
				>
                     <Label text='New Password'/>
                </Item>

                <Item
				    dataField={'confirmPassword'}
				    editorType={'dxTextBox'}
                    editorOptions={passwordEditorOptions}
                >
                    <Label text='Confirm New Password'/>
                </Item>

				<ButtonItem>
					<ButtonOptions
						width={'100%'}
						type={'default'}
						useSubmitBehavior={true}
					>
						<span className="dx-button-text">
						{
							loading
							? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
							: 'Reset Password'
						}
						</span>
					</ButtonOptions>
				</ButtonItem>
                <Item>
                    <div className={'login-link'}>
                        Return to <Link to={'/login'}>Sign In</Link>
                    </div>
                </Item>
			</Form>

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={2000}
            />
		</form>
	);
}