import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './PartnerDoctorListDatagrid.css';

export default function PartnerDoctorListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [formData, setformData] = useState({ startDate: '', endDate: '' });
    const formDataRef = useRef(null)

    const [loadingExport, setLoadingExport] = useState(false);

    function handleRowClick(e) {
        const doctorToken = e.data.userToken;
        navigate('/partner-doctor-info', { state: { doctorToken: doctorToken } });
    }

    const onSubmitExport = async (e) => {
        e.preventDefault();

        setLoadingExport(true);

        var currentDate = new Date();
        var currentDateString = currentDate.getFullYear() + (((currentDate.getMonth()+1) < 10) ? ('0' + (currentDate.getMonth()+1)) : (currentDate.getMonth()+1)) + (((currentDate.getDate()) < 10) ? ('0' + (currentDate.getDate())) : (currentDate.getDate()))
        var fileName = 'integratedportal_partner_Log_' + currentDateString;

        var startDateString = formData.startDate.getFullYear() + '-' + (((formData.startDate.getMonth()+1) < 10) ? ('0' + (formData.startDate.getMonth()+1)) : (formData.startDate.getMonth()+1)) + '-' + (((formData.startDate.getDate()) < 10) ? ('0' + (formData.startDate.getDate())) : (formData.startDate.getDate())) + 'T00:00:00.000Z'
        var endDateString = formData.endDate.getFullYear() + '-' + (((formData.endDate.getMonth()+1) < 10) ? ('0' + (formData.endDate.getMonth()+1)) : (formData.endDate.getMonth()+1)) + '-' + (((formData.endDate.getDate()) < 10) ? ('0' + (formData.endDate.getDate())) : (formData.endDate.getDate())) + 'T23:59:59.000Z'

        try {
            const response = await axios.post(backendlink + 'partner/exportpatientlogs',

            {
                startdate: startDateString,
                enddate: endDateString,
                usertoken: user.userToken
            }, 
            {
                headers: {
                    token: user.token
                },
                responseType: 'blob' // set the response type to blob 
            }).then((res)=>{
                console.log(res)

                setLoadingExport(false);

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName + '.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        } catch (error) {
            setLoadingExport(false);

            console.log(error);
            alert(error);
        }
    }

    useEffect(() => {
        // console.log(user.token)
        // console.log(user.userToken)

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'partner/getalldoctors',
                {
                    usertoken: user.userToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    console.log(response.data.result)

                    const outputArray = response.data.result[0].map(item => {
                        var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')

                        return { doctorName: item.name, description: item.description0, status: statusString, phone: item.phone, email: item.email, linkedCustomer: item.linkedcustomer, userToken: item.userToken };
                    });
                    setUsers(outputArray)
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();

        var dateStartdate = new Date(Date.now() - 86400000)
        var dateEnddate = new Date()

        setformData({ startDate: dateStartdate, endDate: dateEnddate })
    }, [user]);

    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

  return (
    <React.Fragment>
        <DataGrid dataSource={users} onRowClick={handleRowClick}>
            <Column dataField="doctorName"  caption='Doctor' />
            <Column dataField="status"  caption='Status' />
            <Column dataField='description'  caption='Description' />
            <Column dataField='phone'  caption='Phone Number' />
            <Column dataField="email" caption='Contact Email' />
            <Column dataField="linkedCustomer" caption='Linked Organization' />
            <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
            <Paging defaultPageSize={5} />
            <FilterRow visible={true} />
            <Lookup />
            {/* <Export enabled={true} /> */}
            {/* <HeaderFilter visible={true} /> */}
        </DataGrid>

        <form onSubmit={onSubmitExport}>
            <Form formData={formData} ref={formDataRef} disabled={false} colCount={3}>

                <Item
                    dataField={'startDate'}
                    editorType={'dxDateBox'}
                    editorOptions={dateoptions}
                >
                    <Label text='Start Date' />
                </Item>

                <Item
                    dataField={'endDate'}
                    editorType={'dxDateBox'}
                    editorOptions={dateoptions}
                >
                    <Label text='End Date' />
                </Item>

                <ButtonItem
                    marginTop={'auto'}
                    marginBottom={'0px'}
                    style={"margin-top: auto; margin-bottom: 0px"}
                    width={'100%'}
                >
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                        {
                            loadingExport
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Export')
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>
        </form>
    </React.Fragment>
  )

}
