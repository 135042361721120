import { HomePage, ChangePasswordPage } from './pages';
import { PartnerCustomerPage, PartnerCustomerInfoPage, PartnerCustomerEditPage, PartnerCustomerChangepasswordPage, PartnerDoctorInfoPage, PartnerDoctorEditPage, PartnerDoctorChangepasswordPage, PartnerPatientInfoPage, PartnerPatientEditPage, PartnerPatientChangepasswordPage, PartnerPatientLogPage, PartnerBillingPage, PartnerAdduserPage } from './pages';
import { CustomerDoctorPage, CustomerDoctorInfoPage, CustomerDoctorChangepasswordPage, CustomerDoctorEditPage, CustomerAddUserPage, CustomerPatientInfoPage, CustomerPatientEditPage, CustomerPatientChangepasswordPage, CustomerPatientLogPage } from './pages'
import { DoctorPatientPage, DoctorPatientInfoPage, DoctorPatientEditPage, DoctorAddUserPage, DoctorPatientChangepasswordPage, DoctorPatientLogPage, DoctorPatientMessagePage } from './pages'
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    // {
    //     path: '/tasks',
    //     element: TasksPage
    // },
    {
        path: '/changepassword',
        element: ChangePasswordPage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/partner-customer',
        element: PartnerCustomerPage
    },
    {
        path: '/partner-customer-info',
        element: PartnerCustomerInfoPage
    },
    {
        path: '/partner-customer-edit',
        element: PartnerCustomerEditPage
    },
    {
        path: '/partner-customer-changepassword',
        element: PartnerCustomerChangepasswordPage
    },
    {
        path: '/partner-doctor-info',
        element: PartnerDoctorInfoPage
    },
    {
        path: '/partner-doctor-edit',
        element: PartnerDoctorEditPage
    },
    {
        path: '/partner-doctor-changepassword',
        element: PartnerDoctorChangepasswordPage
    },
    {
        path: '/partner-patient-info',
        element: PartnerPatientInfoPage
    },
    {
        path: '/partner-patient-edit',
        element: PartnerPatientEditPage
    },
    {
        path: '/partner-patient-changepassword',
        element: PartnerPatientChangepasswordPage
    },
    {
        path: '/partner-patient-log',
        element: PartnerPatientLogPage
    },
    {
        path: '/partner-billing',
        element: PartnerBillingPage
    },
    {
        path: '/partner-adduser',
        element: PartnerAdduserPage
    },
    {
        path: '/customer-doctor',
        element: CustomerDoctorPage
    },
    {
        path: '/customer-doctor-info',
        element: CustomerDoctorInfoPage
    },
    {
        path: '/customer-doctor-changepassword',
        element: CustomerDoctorChangepasswordPage
    },
    {
        path: '/customer-doctor-edit',
        element: CustomerDoctorEditPage
    },
    {
        path: '/customer-addusers',
        element: CustomerAddUserPage
    },
    {
        path: '/customer-patient-info',
        element: CustomerPatientInfoPage
    },
    {
        path: '/customer-patient-edit',
        element: CustomerPatientEditPage
    },
    {
        path: '/customer-patient-changepassword',
        element: CustomerPatientChangepasswordPage
    },
    {
        path: '/customer-patient-log',
        element: CustomerPatientLogPage
    },
    {
        path: '/doctor-patient',
        element: DoctorPatientPage
    },
    {
        path: '/doctor-patient-info',
        element: DoctorPatientInfoPage
    },
    {
        path: '/doctor-patient-edit',
        element: DoctorPatientEditPage
    },
    {
        path: '/doctor-addusers',
        element: DoctorAddUserPage
    },
    {
        path: '/doctor-patient-changepassword',
        element: DoctorPatientChangepasswordPage
    },
    // {
    //     path: '/doctor-patient-log',
    //     element: DoctorPatientLogPage
    // },
    {
        path: '/doctor-patient-message',
        element: DoctorPatientMessagePage
    },
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
