import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
	Item,
	Label,
	ButtonItem,
	ButtonOptions,
	RequiredRule,
	EmailRule
} from 'devextreme-react/form';
import { useLocation } from 'react-router-dom';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';

export default function ValidateOtpForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
    const [loadingResend, setLoadingResend] = useState(false);
	const [formData, setformData] = useState({ otpCode: '' });
    const formDataRef = useRef(null)

    const location = useLocation();

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    async function pressResend() {
        setLoadingResend(true);

        // console.log(location.state.username)

        try {
            const response = await axios.post(backendlink + 'user/getotp',
            {
                username: location.state.username,
            }, 
            {
                headers: {
                    
                }        
            }).then((res)=>{
                setLoadingResend(false);

                // console.log(res.data)

                if (res.data.errorno == 0){
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'success',
                        message: 'Verification code resent',
                    });    
                } else{
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'error',
                        message: res.data.errmessage,
                    });    
                }
            });
        } catch (error) {
            setLoadingResend(false);

            // console.log(error);
            alert(error);
        }
    }

	const onSubmit = useCallback(async (e) => {
		e.preventDefault();

		setLoading(true);

        try {
            const response = await axios.post(backendlink + 'user/submitotp',
            {
                username: location.state?.username,
                userToken: location.state?.userToken,
                otpCode: formData.otpCode
            }, 
            {
                headers: {
                    
                }        
            }).then((res)=>{
                setLoading(false);

                if (res.data.errorno == 0){
                    // console.log(res.data)

                    navigate('/resetpassword', { state: { username: location.state?.username, userToken: location.state?.userToken, otpToken: res.data.result[0].otpToken } });
                } else{
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'error',
                        message: res.data.errmessage,
                    });    
                }
            });
        } catch (error) {
            setLoading(false);

            // console.log(error);
            alert(error);
        }
	}, []);

    useEffect(() => {
        // console.log(location.state)
        
        if (location.state?.username == null || location.state?.userToken == null) {
            navigate('/');
        } else {

        }
    }, []);

    return (
		<form className={'login-form'} onSubmit={onSubmit}>
			<Form formData={formData} ref={formDataRef} disabled={loading}>
				<Item
				    dataField={'otpCode'}
				    editorType={'dxTextBox'}
				>
                    <Label text='Verification Code'/>
                </Item>
				<ButtonItem>
					<ButtonOptions
						width={'100%'}
						type={'default'}
						useSubmitBehavior={true}
					>
						<span className="dx-button-text">
						{
							loading
							? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
							: 'Verify'
						}
						</span>
					</ButtonOptions>
				</ButtonItem>
                <ButtonItem>
					<ButtonOptions
						width={'100%'}
						type={'default'}
                        stylingMode={'outlined'}
						useSubmitBehavior={false}
                        onClick={pressResend}
					>
						<span className="dx-button-text">
                        {
							loadingResend
							? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
							: 'Resend Verification Code'
						}
						</span>
					</ButtonOptions>
				</ButtonItem>
                <Item>
                    <div className={'login-link'}>
                        Return to <Link to={'/login'}>Sign In</Link>
                    </div>
                </Item>
			</Form>

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={2000}
            />
		</form>
	);
}